<!--<template  >-->
<!--&lt;!&ndash;  <div class="hello">&ndash;&gt;-->
<!--&lt;!&ndash;    <h1>{{ msg }}</h1>&ndash;&gt;-->
<!--&lt;!&ndash;    <p>&ndash;&gt;-->
<!--&lt;!&ndash;      For a guide and recipes on how to configure / customize this project,<br>&ndash;&gt;-->
<!--&lt;!&ndash;      check out the&ndash;&gt;-->
<!--&lt;!&ndash;      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.&ndash;&gt;-->
<!--&lt;!&ndash;    </p>&ndash;&gt;-->
<!--&lt;!&ndash;    <h3>Installed CLI Plugins</h3>&ndash;&gt;-->
<!--&lt;!&ndash;    <ul>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;    <h3>Essential Links</h3>&ndash;&gt;-->
<!--&lt;!&ndash;    <ul>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;    <h3>Ecosystem</h3>&ndash;&gt;-->
<!--&lt;!&ndash;    <ul>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->

<!--  <div class="bigtext" >-->
<!--    Coming <br>-->
<!--    Soon-->
<!--  </div>-->
<!--&lt;!&ndash;  <div class="smalltext">&ndash;&gt;-->
<!--&lt;!&ndash;    Our website is under construction, it will see light very soon !&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->

<!--  <div class="footer">-->
<!--    © 2024 Salmi Auto Parts-->
<!--  </div>-->
<!--</template>-->


<template>

  <!--  <background-shapes></background-shapes>-->
  <!--  <BackgroundShapes></BackgroundShapes>-->


  <!--  <div class="shapes-container">-->
  <!--    <div-->
  <!--        class="shapes"-->
  <!--        v-for="item in pieces"-->
  <!--        :key="item"-->
  <!--        :style="{ top: getRandomPosition('y'), left: getRandomPosition('x'), zIndex: -1 }"-->
  <!--    >-->
  <!--      <img :src="require(`@/assets/svg/${item}`)" width="150px" height="150px" :alt="`${item.toString().slice(0, -4)}`" />-->
  <!--  </div>-->
  <!--  </div>-->
  <div class="body">

    <div class="column_sghir">
      <center>
        <img src="../assets/sap.png" width="300px" height="300px" style="margin-bottom: 10px;"
             alt="Salmi auto parts Logo">
      </center>

      <div class="bigtext">
        Coming <br>
        Soon
      </div>
      <br>
      <div class="smalltext">
        Our website is under construction,<br> it will see light very soon ! <br>
        For inquiries: +213 770 225 580

      </div>
    </div>

    <div class="footer" style="height: 20%"
    >
      <!--      <center>-->
      <!--        <img src="../assets/sap.png" width="130px" height="130px" style="margin-bottom: 10px;"-->
      <!--             alt="Salmi auto parts Logo">-->
      <!--      </center>-->
      © 2024 Salmi Auto Parts
    </div>
  </div>


</template>


<script setup>

// import BackgroundShapes from "@/components/backgroundShapes.vue";
import {ref} from 'vue';

// eslint-disable-next-line no-unused-vars
let pieces = ["aile.svg", "bougie.svg", "cylinder.svg", "filtre.svg", "jante.svg", "phare.svg", "pompe_a_eau.svg", "porte.svg", "retroviseur.svg", "suspension.svg", "aile.svg", "bougie.svg", "cylinder.svg", "filtre.svg", "jante.svg", "phare.svg", "pompe_a_eau.svg", "porte.svg", "retroviseur.svg", "suspension.svg",];
// eslint-disable-next-line no-unused-vars
const shapesContainer = ref(null);

// eslint-disable-next-line no-unused-vars
function getRandomPosition(axis) {
  const centerExclusionFactor = 0.3;
  const position = Math.random() * (axis === 'x' ? window.innerWidth : window.innerHeight);

  if (position > window.innerWidth * centerExclusionFactor && position < window.innerWidth * (1 - centerExclusionFactor)) {
    return getRandomPosition(axis);
  }

  return `${position}px`;
}

</script>

<style scoped>


.footer {
  font-size: 15px;
}

.bigtext {
  font-size: 95px;
}

.smalltext {
  font-size: 24px;
}


@media screen and (width < 200px) {
  .bigtext {
    font-size: 18px;
  }

  .smalltext {
    font-size: 7px;
  }

  .footer {
    font-size: 8px;
  }
}

@media screen and (width > 200px) {
  .bigtext {
    font-size: 30px;
  }

  .smalltext {
    font-size: 10px;
  }

  .footer {
    font-size: 12px;
  }
}

@media screen and (width > 400px) {
  .bigtext {
    font-size: 40px;
  }

  .smalltext {
    font-size: 14px;
  }

  .footer {
    font-size: 12px;
  }
}

@media screen and (width > 768px) {
  .bigtext {
    font-size: 60px;
  }

  .smalltext {
    font-size: 15px;
  }

  .footer {
    font-size: 14px;
  }
}

@media screen and (width > 1024px) {
  .bigtext {
    font-size: 70px;
  }

  .smalltext {
    font-size: 18px;
  }

  .footer {
    font-size: 16px;
  }
}

@media screen and (width > 1280px) {
  .bigtext {
    font-size: 80px;
  }

  .smalltext {
    font-size: 20px;
  }

  .footer {
    font-size: 18px;
  }
}

@media screen and (width > 1440px) {
  .bigtext {
    font-size: 88px;
  }

  .smalltext {
    font-size: 22px;
  }

  .footer {
    font-size: 20px;
  }
}

@media screen and (width > 1921px) {
  .bigtext {
    font-size: 95px;
  }

  .smalltext {
    font-size: 24px;
  }

  .footer {
    font-size: 24px;
  }
}

@media screen and (width > 2400px) {
  .bigtext {
    font-size: 120px;
  }

  .smalltext {
    font-size: 30px;
  }

  .footer {
    font-size: 28px;
  }
}


.bigtext {
  font-family: 'Lulo Clean', sans-serif;
  color: black;
  display: grid;
  align-self: center;
  justify-self: center;
  line-height: 1.1;
}

.smalltext {
  font-family: 'Avenir Light', sans-serif;
  color: black;
  display: grid;
  align-self: center;
  justify-self: center;
  letter-spacing: 2px;
}

.footer {
  font-family: 'Avenir Light', sans-serif;
  color: black;
  letter-spacing: 0.05px;
  justify-content: end;
  display: flex;
  flex-direction: column;
  height: 0%;
}


.body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('../assets/back.svg');
  background-size: 100% 105%;
  background-repeat: repeat;
  background-position: center top;
  overflow: hidden;
}

.column_sghir {
  display: flex;
  flex-direction: column;
  height: 200%;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'Lulo Clean';
  src: url('../assets/fonts/Lulo Clean One Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Light';
  src: url("../assets/fonts/Avenir Light.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
